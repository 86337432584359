import React, { Fragment, useMemo, useState } from 'react';

import { Button, Text } from '@BICE_Vida/components';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Container } from '@/designSystem/Container/Container';
import PromoModal from '@/components/PromoHeader/PromoModal';

import componentsId from '@/utils/componentsId';

import styles from './CyberBanner.module.scss';
import { IntervalCyber } from '@/utils/format';
import { CYBER_IMAGES } from '@/utils/assets';
import useIsMobile from '@/utils/hooks/useMobile';
import { breakPointMd } from '@/utils/constants';

type InfoType = {
  cyberDay: IntervalCyber | null;
};

const CyberBanner = ({ cyberDay }: InfoType) => {
  const [isOpen, setIsOpen] = useState(false);
  const showMobile = useIsMobile(breakPointMd);
  const router = useRouter();

  const handleClick = () => {
    setIsOpen(true);
  };

  const handleButton = () => {
    router.push('/cotizacion/numero-trabajadores');
  };

  const subtext = useMemo(() => {
    switch (cyberDay) {
      case IntervalCyber.CyberDay:
        return '3, 4 Y 5 DE JUNIO';
      case IntervalCyber.Extension:
        return '6 Y 7 DE JUNIO';
      case IntervalCyber.CyberDayLastDay:
        return '6 Y 7 DE JUNIO';
      default:
        return '';
    }
  }, [cyberDay]);

  return (
    <section id={componentsId.landing.circle.section} className={styles.container_sections}>
      <PromoModal isOpen={isOpen} setIsOpen={setIsOpen} cyberDay={cyberDay} />

      <Container className={styles.container_fluid} fluid>
        <div className={styles.container}>
          <div className={styles.logo_section}>
            {!showMobile ? (
              <Fragment>
                <Image
                  src={CYBER_IMAGES.GRADIENT_ARC}
                  alt=""
                  width={580}
                  height={300}
                  quality={100}
                  className={styles.gradient_circle}
                />
                <Image
                  src={CYBER_IMAGES.CAT_POINTER}
                  alt=""
                  width={360}
                  height={360}
                  quality={100}
                  className={styles.cat_pointer}
                />
              </Fragment>
            ) : (
              <div className={styles.container_logo_section_mobile}>
                <Image
                  src={CYBER_IMAGES.CAT_POINTER_CIRCLE}
                  alt=""
                  width={100}
                  height={80}
                  quality={100}
                  className={styles.cat_pointer_mobile}
                />
                <div className={styles.container_logo_cyber}>
                  {cyberDay === IntervalCyber.Extension ? (
                    <Text className={styles.extends}>¡Extendimos!</Text>
                  ) : cyberDay === IntervalCyber.CyberDayLastDay ? (
                    <Text className={styles.last_day}>Último día!</Text>
                  ) : null}

                  <Image src={CYBER_IMAGES.LOGO} width={136} height={60} alt="" quality={80} />
                  <Text className={styles.paragraph_right_section}>{subtext}</Text>
                </div>
              </div>
            )}
          </div>
          <div className={styles.middle_section}>
            <div className={styles.price_section}>
              <Text className={styles.title_header}>¡Contrata hoy!</Text>
              <Text className={styles.paragraph_header}>
                Protege a tus trabajadores y llévate <span>descuentos</span> de hasta
              </Text>
              <div className={styles.paragraph_price}>
                <Text tag="p">50</Text>
                <div>
                  <div className={styles.percentage}>%</div>
                  <div className={styles.discount}>DCTO</div>
                </div>
                <div className={styles.details_container}>
                  <div className={styles.detail}>En una facturación.</div>
                  <div onClick={handleClick} className={styles.detail_button}>
                    Ver detalles
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.right_section}>
            {!showMobile && (
              <Fragment>
                {cyberDay === IntervalCyber.Extension ? (
                  <Text className={styles.extends}>¡Extendimos!</Text>
                ) : cyberDay === IntervalCyber.CyberDayLastDay ? (
                  <Text className={styles.last_day}>Último día!</Text>
                ) : null}{' '}
                <Image src={CYBER_IMAGES.LOGO} width={170} height={75} alt="" quality={80} />
                <Text className={styles.paragraph_right_section}>{subtext}</Text>
              </Fragment>
            )}

            <div className={styles.btn_container}>
              <Button
                className={styles.cyber_button}
                size="xlarge"
                text="Cotiza ahora"
                variant="primary"
                onClick={handleButton}
              />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CyberBanner;
