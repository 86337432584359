import routes from './routes';

import { PDFS } from './assets';

export const removeTypename = (value: any): any => {
  if (value === null || value === undefined) {
    return value;
  }

  if (Array.isArray(value)) {
    return value.map((v) => removeTypename(v));
  }

  if (typeof value === 'object') {
    const newObj: any = {};

    Object.entries(value).forEach(([key, v]) => {
      if (key !== '__typename') {
        newObj[key] = removeTypename(v);
      }
    });

    return newObj;
  }

  return value;
};

export const stringToTemplate = (template: string) => {
  const variables: any = {
    ...routes,
    baseUrl: process.env.baseUrl,
    urlPolicyPDF: `${process.env.assetsUrl}${PDFS.POLICY}`,
    urlPlansPDF: `${process.env.assetsUrl}${PDFS.PLANS}`,
  };

  return template.replace(
    new RegExp('{([^{]+)}', 'g'),
    (_unused: any, varName: string) => variables[varName]
  );
};
