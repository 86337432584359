import React, { Fragment, useEffect } from 'react';
import dynamic from 'next/dynamic';

import { useSelector } from 'react-redux';
import Hero from './Hero';
import Plans from './AvailablePlans';
import { trackViewPageEvent } from '@/utils/dataLayer/home';
import CyberBanner from './CyberBanner';
import { useCyberDates, useExecutive } from '@/src/customHooks';
import * as plansSelectors from '@/redux/plans/selectors';
import { getBranding } from '@/redux/wizard/selectors';
import {
  AvailablePlans as AvailablePlansType,
  AvailablePlan as AvailablePlanType,
} from '@/src/types/availablePlans';

const CoveragesWithLoading = dynamic(() => import('./Coverages'), {
  loading: () => <p>Loading...</p>,
});

const Benefits = dynamic(() => import('./Benefits'));
const RequirementsToContract = dynamic(() => import('./RequirementsToContract'));
const BannerFooter = dynamic(() => import('./BannerFooter'));
const HelpBanner = dynamic(() => import('./HelpBanner'));
const PawerBanner = dynamic(() => import('./PawerBanner'));
const Features = dynamic(() => import('./Features'));

type HomeType = {
  loading: boolean;
};

const Home = ({ loading }: HomeType) => {
  useEffect(() => {
    trackViewPageEvent(branding.code);
  }, []);

  useExecutive();
  const availablePlans: AvailablePlansType = useSelector(plansSelectors.getAvailablePlans);
  const branding = useSelector(getBranding);

  const availablePlansFiltered = availablePlans.find(
    (plan: AvailablePlanType) => plan.base === 'elite'
  );

  const isPawerAssistance = !!availablePlansFiltered?.assistances?.find(
    (assistances) => assistances.provider === 'pawer'
  );

  const { isPromo, isCyberDayToday, cyberDay } = useCyberDates(new Date());

  return (
    <Fragment>
      <Hero />
      <Features />
      {(isCyberDayToday || isPromo) && <CyberBanner cyberDay={cyberDay} />}
      {!loading && <Plans />}
      {!loading && isPawerAssistance && <PawerBanner />}
      <HelpBanner />
      <CoveragesWithLoading />
      <Benefits />
      <RequirementsToContract />
      <BannerFooter />
    </Fragment>
  );
};

export default Home;
