/* eslint-disable import/prefer-default-export */
import { SET_AVAILABLE_PLANS } from './actionTypes';

import { removeTypename } from '../../utils/parser';

export const setAvailablePlans = (payload: any) => {
  return {
    type: SET_AVAILABLE_PLANS,
    payload: removeTypename(payload),
  };
};
