import React from 'react';

import { Button } from '@BICE_Vida/components';

import { Modal, ModalBody, ModalContent } from '@BICE_Vida/scp-components';

import { HERO_ICON } from '@/utils/assets';

import styles from './RequirementsModal.module.scss';
import { landingText } from '@/utils/generalTexts';
import Alert from '@/designSystem/Alert';

type RequirementsModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

function RequirementsModal({ isOpen, setIsOpen }: RequirementsModalProps) {
  const onClickFunction = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClickFunction} closeOnOverlay classNameModal={styles.modal}>
        <ModalBody
          img={HERO_ICON.FOLDERS}
          className={styles.modal_body}
          content={
            <ModalContent
              title={landingText.hero.modal.title}
              className={styles.text_modal_content}
            >
              <div className={styles.text_modal_body}>{landingText.hero.modal.body}</div>

              <Alert text={landingText.hero.modal.alert} customClass={styles.alert_requeriments} />
            </ModalContent>
          }
        />
        <div className={styles.container_buttons}>
          <Button
            text={landingText.hero.modal.button}
            variant="primary"
            size="large"
            onClick={onClickFunction}
          />
        </div>
      </Modal>
    </div>
  );
}

export default RequirementsModal;
