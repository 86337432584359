import React from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

import { Button, Text } from '@BICE_Vida/components';

import styles from './AvailablePlans.module.scss';
import componentsId from '../../../utils/componentsId';
import { landingText, availablePlanCard } from '../../../utils/generalTexts';
import routes from '../../../utils/routes';

import { UF as UFType } from '../../../types/uf';
import {
  AvailablePlans as AvailablePlansType,
  AvailablePlan as AvailablePlanType,
} from '../../../types/availablePlans';

import * as ufSelectors from '../../../redux/uf/selectors';
import * as plansSelectors from '../../../redux/plans/selectors';
import { clickActionsEvents, trackClick } from '@/utils/dataLayer/home';
import { formatMoney } from '@/utils/format';
import AvailablePlanCard from '@/components/AvailablePlanCard';

const AvailablePlans = () => {
  const router = useRouter();

  const goToWizardHandler = () => {
    trackClick({ action: clickActionsEvents.hirePlans });
    router.push(routes.wizard);
  };
  const UF: UFType = useSelector(ufSelectors.getUF);
  const availablePlans: AvailablePlansType = useSelector(plansSelectors.getAvailablePlans);

  const availablePlansFiltered = availablePlans.filter(
    (card: AvailablePlanType) => card.base !== null && card.hidePlan !== true
  );
  const dateFormatted = `${UF.day}/${UF.month}/${UF.year}`;

  return (
    <section
      id={componentsId.landing.availablePlans.section}
      className={styles.available_plans_section}
    >
      <div className={styles.available_plans_container}>
        <Text className={styles.available_plans_title} tag="p">
          {landingText.availablePlans.title}
        </Text>
        <Text className={styles.available_plans_subtitle} tag="p">
          {landingText.availablePlans.subtitle}
        </Text>
        <div className={styles.available_plans_cards}>
          {availablePlansFiltered.map((card, index: number) => (
            <AvailablePlanCard
              id={`${card.base}_card`}
              idx={index}
              customClass={styles.available_plans_card}
              availablePlan={card}
              UF={UF}
              key={index}
              btnAction={{
                id: `${card.base}_btn`,
                text: availablePlanCard.btn,
                onClickEvent: () => {
                  trackClick({
                    action: clickActionsEvents.seeDetailPlan(card.description),
                  });
                },
              }}
            />
          ))}
        </div>
        <div className={styles.available_plans_legend_section}>
          <Text className={styles.disclaimer_text}>
            {landingText.coverages.warningUF(formatMoney(UF.value), dateFormatted)}
          </Text>
        </div>
      </div>
      <div className={styles.available_plans_buttons}>
        <Button
          id={componentsId.landing.availablePlans.buttonNow}
          className={styles.hero_button}
          icon="arrow_forward"
          iconPosition="right"
          size="large"
          text={landingText.availablePlans.buttonNow}
          variant="primary"
          onClick={goToWizardHandler}
          aria-label={landingText.availablePlans.buttonNow}
        />
      </div>
    </section>
  );
};

export default AvailablePlans;
