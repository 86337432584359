import React from 'react';
import classnames from 'classnames';

import Image from 'next/image';
import Text from '../Text';

import styles from './Pill.module.scss';

type PillT = {
  text: string;
  imgSrc: string;
  className?: string;
};

function Pill({ text, className, imgSrc }: PillT) {
  const pillClass = classnames(styles.pill_container, {
    [`${className}`]: className,
  });

  return (
    <div className={pillClass}>
      <Image src={imgSrc} alt="" width={40} height={36} />
      <div className={styles.pill_text_container}>
        <Text text={text} />
      </div>
    </div>
  );
}

export default Pill;
