import { useEffect, useState } from 'react';

const useIsMobile = (breakPointMd: number) => {
  const [showMobile, setShowMobile] = useState(false);

  useEffect(() => {
    const onResize = () => {
      setShowMobile(window.innerWidth < breakPointMd);
    };

    window.addEventListener('resize', onResize);

    onResize();

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [breakPointMd]);

  return showMobile;
};

export default useIsMobile;
