import React, { useState } from 'react';

import Image from 'next/image';

import { useRouter } from 'next/router';

import { Button, Icon, Text } from '@BICE_Vida/components';

import Pill from '@/designSystem/Pill';

import componentsId from '@/utils/componentsId';
import { landingImageHero, pillImage } from '@/utils/constants';
import { landingText } from '@/utils/generalTexts';
import routes from '@/utils/routes';
import { clickActionsEvents, trackClick } from '@/utils/dataLayer/home';

import styles from './Hero.module.scss';
import { HERO_ICON } from '@/utils/assets';
import RequirementsModal from './RequirementsModal';

const Hero = () => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState(false);

  const btnEstimateClick = () => {
    router.push(routes.wizard);
    trackClick({ action: clickActionsEvents.hireBody });
  };

  const onClick = () => {
    const element = document.getElementById(componentsId.landing.features.section);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const onClickModal = () => {
    setIsOpen(!isOpen);
    trackClick({ action: clickActionsEvents.btnRequeriments });
  };

  return (
    <section className={styles.hero_section} id={componentsId.landing.hero.section}>
      <RequirementsModal isOpen={isOpen} setIsOpen={setIsOpen} />
      <div>
        <Pill className={styles.hero_pill} text={landingText.hero.pill} imgSrc={pillImage} />
        <div className={styles.wrap}>
          <div className={styles.description_section}>
            <Text tag="h1" className={styles.hero_title}>
              {landingText.hero.title}
            </Text>
            <div className={styles.list}>
              {landingText.hero.subtitles.map((text, i) => (
                <Text key={i} tag="p" className={styles.hero_subtitle}>
                  {text}
                </Text>
              ))}
            </div>

            <div className={styles.hero_button_container}>
              <Button
                id={componentsId.landingHeroBtnPrices}
                text="Ver requisitos"
                size="large"
                onClick={onClickModal}
                aria-label="Ver requisitos"
                variant="text"
              />
              <Button
                id={componentsId.landingHeroBtnPrices}
                text={landingText.hero.button}
                size="large"
                onClick={btnEstimateClick}
                icon="arrow_forward"
                iconPosition="right"
                aria-label={landingText.hero.button}
              />
            </div>
          </div>
          <div className={styles.hero_imagen_container}>
            <div className={styles.hero_icons_container}>
              {[HERO_ICON.HEART, HERO_ICON.SHIELD].map((icon, index) => (
                <Image
                  key={index}
                  src={icon}
                  alt="bicevida beneficios vida"
                  width={64}
                  height={64}
                />
              ))}
            </div>
            <div className={styles.hero_image}>
              {landingImageHero.map((item, index) => (
                <Image
                  priority={index === 0}
                  key={`${item.alt}_${index}`}
                  quality={100}
                  src={item.src}
                  alt={item.alt}
                  width={item.width}
                  height={item.height}
                />
              ))}
            </div>
          </div>
        </div>
        <div />
        <div>
          <Icon
            id={componentsId.landingHeroBtnScroll}
            name="arrow_back"
            onClick={onClick}
            className={styles.hero_arrow}
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
