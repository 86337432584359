import React, { useEffect } from 'react';
import { NextPage } from 'next';

import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import Home from '../components/Home';
import LayoutCustom from '../components/LayoutCustom';

import { setAvailablePlans } from '@/redux/plans/actions';
import { setUF } from '@/redux/uf/actions';
import { unsetError } from '@/redux/app/actions';

import { getPlansService, getUFService } from '@/graphQL/onLoad/getProps';
import { UF } from '../types/uf';
import { homeEvents } from '@/utils/dataLayer/home';
import listPlansAndUFToday from '@/graphQL/queries/listPlansAndUFToday';
import { useBranding } from '../customHooks';

const HomePage: NextPage<{ getUFToday: UF; listPlans: unknown }> = ({ getUFToday, listPlans }) => {
  const dispatch = useDispatch();
  const { code, loading: loadingBrand } = useBranding();

  const [getInitialData, { loading }] = useLazyQuery(listPlansAndUFToday, {
    onCompleted: (data) => {
      if (data) {
        dispatch(setAvailablePlans(data.listPlans));
        dispatch(setUF(data.getUFToday));
        dispatch(unsetError());
      }
    },
  });

  dispatch(setUF(getUFToday));
  dispatch(unsetError());

  const eventParams = {
    ...homeEvents.hiringFloatClick,
  };

  useEffect(() => {
    if (!!code) {
      getInitialData({ variables: { brokerCode: code } });
    }
    dispatch(setAvailablePlans(listPlans));
    dispatch(setUF(getUFToday));
    dispatch(unsetError());
  }, [code]);

  return (
    <LayoutCustom eventParams={eventParams} shouldRenderCyberDayHeader={loadingBrand}>
      <Home loading={loading} />
    </LayoutCustom>
  );
};

export async function getStaticProps() {
  const getUFToday = await getUFService();
  const listPlans = await getPlansService();

  return {
    revalidate: process.env.STAGE === 'prod' ? 300 : 30,
    props: {
      getUFToday,
      listPlans,
    },
  };
}

export default HomePage;
